var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"healthReport"},[_c('div',{staticClass:"healthReport-t"},[_c('div',{class:['item', { active: true }]},[_vm._v("血压")])]),_c('div',{staticClass:"healthReport-c"},[_c('div',{staticClass:"dongmai"},[_c('div',{staticClass:"title"},[_vm._v("血压")]),_c('div',{staticClass:"intro"},[_vm._v(" 血压指血液在血管内流动时作用于单位面积血管壁的侧压力，检查所测量的一般是体循环的动脉血压，包括收缩压与舒张压。 血压正常代表人体心脏和血管都处于正常运转状态。 ")]),_c('div',{staticClass:"thirdItem"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-t"},[_vm._v("收缩压")]),_c('div',{staticClass:"item-b"},[_vm._v(_vm._s(_vm.bloodPressDetail.high)+" mmHg")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-t"},[_vm._v("舒张压")]),_c('div',{staticClass:"item-b"},[_vm._v(_vm._s(_vm.bloodPressDetail.low)+" mmHg")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-t"},[_vm._v("心率")]),_c('div',{staticClass:"item-b"},[_vm._v(_vm._s(_vm.bloodPressDetail.heart)+" bpm")])])]),_c('div',{staticClass:"title"},[_vm._v("血压测量结果分布")]),_c('div',{staticClass:"High"},[_c('div',{staticClass:"targetItem"},[_c('div',{staticClass:"item1 item"},[_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.high, 0, 90, 210, 0, '最小'))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.high, 0, 90, 210, 0, 1.6)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.high))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("偏低")])]),_c('div',{staticClass:"item2 item"},[_c('div',{staticClass:"num"},[_vm._v("90/60")]),_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.high, 90, 130, 199, 0))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.high, 90, 130, 199, 0, 1.6)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.high))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("正常")])]),_c('div',{staticClass:"item3 item"},[_c('div',{staticClass:"num"},[_vm._v("130/85")]),_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.high, 130, 139, 210, 0))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.high, 130, 139, 210, 0, 2)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.high))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("正常偏高")])]),_c('div',{staticClass:"item4 item"},[_c('div',{staticClass:"num"},[_vm._v("139/89")]),_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.high, 139, 159, 199, 0))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.high, 139, 159, 199, 0, 1.6)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.high))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("轻度高血压")])]),_c('div',{staticClass:"item5 item"},[_c('div',{staticClass:"num"},[_vm._v("159/99")]),_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.high, 159, 179, 199, 0, '最大'))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.high, 159, 179, 199, 0, 1.6)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.high))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("中度高血压")])]),_c('div',{staticClass:"item6 item"},[_c('div',{staticClass:"num"},[_vm._v("179/109")]),_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.high, 179, 199, 199, 0, '最大'))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.high, 179, 199, 199, 0, 1.6)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.high))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("重度高血压")])])])]),_c('div',{staticClass:"title"},[_vm._v("心率测量结果分布")]),_c('div',{staticClass:"Heart"},[_c('div',{staticClass:"targetItem"},[_c('div',{staticClass:"item1 item"},[_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.heart, 0, 60, 140, 0, '最小'))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.heart, 0, 60, 140, 0, 220)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.heart))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("偏低")])]),_c('div',{staticClass:"item3 item"},[_c('div',{staticClass:"num"},[_vm._v("60")]),_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.heart, 60, 100, 140, 0))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.heart, 60, 100, 140, 0, 220)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.heart))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("正常")])]),_c('div',{staticClass:"item5 item"},[_c('div',{staticClass:"num"},[_vm._v("100")]),_c('div',{staticClass:"item-t"},[(_vm.returnCircle(_vm.bloodPressDetail.heart, 100, 140, 140, 0, '最大'))?_c('div',{staticClass:"circle",style:({
                  left: _vm.returnLeft(_vm.bloodPressDetail.heart, 100, 140, 140, 0, 220)
                })},[_c('div',{staticClass:"numItem"},[_vm._v(_vm._s(_vm.bloodPressDetail.heart))])]):_vm._e()]),_c('div',{staticClass:"item-b"},[_vm._v("偏高")])])])]),_c('div',{staticClass:"title"},[_vm._v("血压等级判断标准")]),_c('div',{staticClass:"bloodPreList oTable"},_vm._l((_vm.bloodPreGrade),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{staticClass:"item-c"},[_vm._v(" "+_vm._s(item.High ? item.High : '--')+" ")]),_c('div',{staticClass:"item-r"},[_vm._v(" "+_vm._s(item.Low)+" ")])])}),0),_c('div',{staticClass:"careInfo"},[_vm._v("注：判断标准参考《中国高血压防治指南2018年修订版》，当收缩压和舒张压分属于不同级别时，以较高的分级为主")]),_c('div',{staticClass:"title"},[_vm._v("检测建议")]),_c('div',{staticClass:"advise"},[_vm._v(_vm._s(_vm.bloodPressDetail.bloodAdvise))]),_c('div',{staticClass:"title"},[_vm._v("趋势图")]),_c('div',{staticClass:"trend-chart",attrs:{"id":"trendChart"}})])]),_c('div',{staticClass:"healthReport-b"})])
}
var staticRenderFns = []

export { render, staticRenderFns }