<template>
  <div class="healthReport">
    <div class="healthReport-t">
      <div :class="['item', { active: true }]">血压</div>
    </div>
    <div class="healthReport-c">
      <div class="dongmai">
        <div class="title">血压</div>
        <div class="intro">
          血压指血液在血管内流动时作用于单位面积血管壁的侧压力，检查所测量的一般是体循环的动脉血压，包括收缩压与舒张压。
          血压正常代表人体心脏和血管都处于正常运转状态。
        </div>
        <div class="thirdItem">
          <div class="item">
            <div class="item-t">收缩压</div>
            <div class="item-b">{{ bloodPressDetail.high }} mmHg</div>
          </div>
          <div class="item">
            <div class="item-t">舒张压</div>
            <div class="item-b">{{ bloodPressDetail.low }} mmHg</div>
          </div>
          <div class="item">
            <div class="item-t">心率</div>
            <div class="item-b">{{ bloodPressDetail.heart }} bpm</div>
          </div>
        </div>
        <div class="title">血压测量结果分布</div>
        <div class="High">
          <div class="targetItem">
            <div class="item1 item">
              <div class="item-t">
                <div
                  class="circle"
                  v-if="returnCircle(bloodPressDetail.high, 0, 90, 210, 0, '最小')"
                  :style="{
                    left: returnLeft(bloodPressDetail.high, 0, 90, 210, 0, 1.6)
                  }"
                >
                  <div class="numItem">{{ bloodPressDetail.high }}</div>
                </div>
              </div>
              <div class="item-b">偏低</div>
            </div>
            <div class="item2 item">
              <div class="num">90/60</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(bloodPressDetail.high, 90, 130, 199, 0, 1.6)
                  }"
                  v-if="returnCircle(bloodPressDetail.high, 90, 130, 199, 0)"
                >
                  <div class="numItem">{{ bloodPressDetail.high }}</div>
                </div>
              </div>
              <div class="item-b">正常</div>
            </div>
            <div class="item3 item">
              <div class="num">130/85</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(bloodPressDetail.high, 130, 139, 210, 0, 2)
                  }"
                  v-if="returnCircle(bloodPressDetail.high, 130, 139, 210, 0)"
                >
                  <div class="numItem">{{ bloodPressDetail.high }}</div>
                </div>
              </div>
              <div class="item-b">正常偏高</div>
            </div>
            <div class="item4 item">
              <div class="num">139/89</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(bloodPressDetail.high, 139, 159, 199, 0, 1.6)
                  }"
                  v-if="returnCircle(bloodPressDetail.high, 139, 159, 199, 0)"
                >
                  <div class="numItem">{{ bloodPressDetail.high }}</div>
                </div>
              </div>
              <div class="item-b">轻度高血压</div>
            </div>
            <div class="item5 item">
              <div class="num">159/99</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(bloodPressDetail.high, 159, 179, 199, 0, 1.6)
                  }"
                  v-if="returnCircle(bloodPressDetail.high, 159, 179, 199, 0, '最大')"
                >
                  <div class="numItem">{{ bloodPressDetail.high }}</div>
                </div>
              </div>
              <div class="item-b">中度高血压</div>
            </div>
            <div class="item6 item">
              <div class="num">179/109</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(bloodPressDetail.high, 179, 199, 199, 0, 1.6)
                  }"
                  v-if="returnCircle(bloodPressDetail.high, 179, 199, 199, 0, '最大')"
                >
                  <div class="numItem">{{ bloodPressDetail.high }}</div>
                </div>
              </div>
              <div class="item-b">重度高血压</div>
            </div>
          </div>
        </div>
        <div class="title">心率测量结果分布</div>
        <div class="Heart">
          <div class="targetItem">
            <div class="item1 item">
              <div class="item-t">
                <div
                  class="circle"
                  v-if="returnCircle(bloodPressDetail.heart, 0, 60, 140, 0, '最小')"
                  :style="{
                    left: returnLeft(bloodPressDetail.heart, 0, 60, 140, 0, 220)
                  }"
                >
                  <div class="numItem">{{ bloodPressDetail.heart }}</div>
                </div>
              </div>
              <div class="item-b">偏低</div>
            </div>
            <div class="item3 item">
              <div class="num">60</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(bloodPressDetail.heart, 60, 100, 140, 0, 220)
                  }"
                  v-if="returnCircle(bloodPressDetail.heart, 60, 100, 140, 0)"
                >
                  <div class="numItem">{{ bloodPressDetail.heart }}</div>
                </div>
              </div>
              <div class="item-b">正常</div>
            </div>
            <div class="item5 item">
              <div class="num">100</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(bloodPressDetail.heart, 100, 140, 140, 0, 220)
                  }"
                  v-if="returnCircle(bloodPressDetail.heart, 100, 140, 140, 0, '最大')"
                >
                  <div class="numItem">{{ bloodPressDetail.heart }}</div>
                </div>
              </div>
              <div class="item-b">偏高</div>
            </div>
          </div>
        </div>
        <div class="title">血压等级判断标准</div>
        <div class="bloodPreList oTable">
          <div class="item" v-for="(item, index) in bloodPreGrade" :key="index">
            <div class="item-l">
              {{ item.value }}
            </div>
            <div class="item-c">
              {{ item.High ? item.High : '--' }}
            </div>
            <div class="item-r">
              {{ item.Low }}
            </div>
          </div>
        </div>
        <div class="careInfo">注：判断标准参考《中国高血压防治指南2018年修订版》，当收缩压和舒张压分属于不同级别时，以较高的分级为主</div>
        <div class="title">检测建议</div>
        <div class="advise">{{ bloodPressDetail.bloodAdvise }}</div>
        <div class="title">趋势图</div>
        <div class="trend-chart" id="trendChart"></div>
      </div>
    </div>
    <div class="healthReport-b"></div>
  </div>
</template>

<script>
import { getBloodPressReportDetailUrl } from './api';
import { hHDictionary } from '../dictionary';
import { bloodPreGrade } from './bloodPreGrade';
import { mapState } from 'vuex';
import { bmiArrMap } from './map';
import { aviArr, apiArr, bloodPreAdvice } from './aviMap';
import * as echarts from "echarts";
// import elementResizeDetectorMaker from 'element-resize-detector';
import moment from "moment";

export default {
  name: 'healthReport',
  data() {
    return {
      bmiArrMap,
      AviLevel: [],
      ApiLevel: [],
      bloodPreGrade,
      hHDictionary,
      examId: '',
      titleList: [],
      reportListData: {
        data: {}
      },
      boneMD: '',
      bloodPre: '',
      peopleComponent: '',
      heightWeight: '',
      BMI: '',
      heightWeightData: {
        Bmi: '',
        advise: ''
      },
      boneMDData: {
        Tscore: '',
        advise: ''
      },
      bloodPreData: {
        Api: '',
        Avi: '',
        High: '',
        Low: '',
        Heart: '',
        advise: '',
        bloodAdvise: ''
      },
      peopleComponentData: {
        BodyFatRating: '',
        LeftHandMuscle: '',
        RightHandMuscle: '',
        LeftFootMuscle: '',
        RightFootMuscle: '',
        BodyMuscleMass: '',
        Bmi: '',
        Weight: '',
        MuscleMass: '',
        NoFatWeight: '',
        FatWeight: '',
        BoneWeight: '',
        BodyWater: '',
        danbai: '',
        advise: ''
      },
      peopleComponentList: [],
      advise: {},
      AviTxt: '',
      ApiTxt: '',
      AviRange: [],
      ApiRange: [],
      bloodPressDetail: {}
      // bloodPressDetail.heart
    };
  },
  created() {
    this.examId = this.$route.query.examId;
  },
  computed: {
    ...mapState(['device', 'userInfo'])
  },
  mounted() {
    let age;
    if (this.userInfo.age) {
      age = this.userInfo.age;
    }
    if (this.getRange(age, aviArr)) {
      this.AviLevel = this.getRange(age, aviArr).split(',');
    }
    if (this.getRange(age, apiArr)) {
      this.ApiLevel = this.getRange(age, apiArr).split(',');
    }
    console.log(hHDictionary);
    this.getExamDetail();
  },
  methods: {
    checkBloodLevel(Low, High) {
      var level = 1;
      if (Low <= 60 || High <= 90) {
        level = 1;
      } else if (Low <= 85 || High <= 130) {
        level = 2;
      } else if (Low <= 89 || High <= 139) {
        level = 3;
      } else if (Low <= 99 || High <= 159) {
        level = 4;
      } else if (Low <= 109 || High <= 179) {
        level = 5;
      } else {
        level = 6;
      }
      return level;
    },
    getRange(age, Arr) {
      var Ranges = Arr;
      if (age < 1) {
        age = 1;
      }
      if (age > 80) {
        age = 80;
      }
      return Ranges[age - 1];
    },
    returnLeft(nowData, begin, end, max, min, width, isF) {
      let oss = document.body.clientWidth / 750;
      let oWidth = 130;
      if (width) {
        oWidth = oss * width;
      }
      if (nowData) {
        if (Number(nowData) > begin && Number(nowData) <= end) {
          let chaData = (((nowData - begin) / (end - begin)) * oWidth).toFixed(2) + 'px';
          return chaData;
        } else {
          if (Number(nowData) > max) {
            if (isF == 1) {
              return 0;
            } else {
              return oWidth + 'px';
            }
          }
          if (Number(nowData) < min) {
            if (isF == 1) {
              return oWidth + 'px';
            } else {
              return 0;
            }
          }
          return null;
        }
      }
    },
    returnCircle(nowData, begin, end, max, min, sign) {
      if (nowData) {
        if (Number(nowData) > begin && Number(nowData) <= end) {
          return true;
        } else {
          if (Number(nowData) > max) {
            if (sign == '最大') {
              return true;
            }
          }
          if (Number(nowData) < min) {
            if (sign == '最小') {
              return true;
            }
          }
          return false;
        }
      }
    },
    async getExamDetail() {
      const params = { id: this.$route.query.examId };
      console.log('params => ', params);
      let res = await this.$axios.get(`${getBloodPressReportDetailUrl}`, { params });
      if (res.code == 200) {
        res.data = res.data || {};
        this.bloodPressDetail = res.data;
        this.bloodPressDetail.bloodAdvise = bloodPreAdvice[this.checkBloodLevel(this.bloodPressDetail.low, this.bloodPressDetail.high)];
        this.$nextTick(() => {
          this.mountTrendChart(document.getElementById('trendChart'), res.data.lastExamList)
        });
      }
    },
    mountTrendChart(dom, lastExamList) {
      // lastExamList.sort((a, b) => new Date(a.examTime).getTime() - new Date(b.examTime).getTime())
      if (!dom) return;
      const myChart = echarts.init(dom);
      myChart.clear();
      const option = {
        legend: {
          top: 0,
          data: ['收缩压', '舒张压']
        },
        grid: {
          top: 40,
          right: 0,
          bottom: 0,
          left: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: lastExamList.map(item => moment(item.examTime).format('M.D'))
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '收缩压',
            data: lastExamList.map(item => item.high),
            type: 'line',
            label: {
              show: true,
              // color: 'inherit'
            }
          },
          {
            name: '舒张压',
            data: lastExamList.map(item => item.low),
            type: 'line',
            label: {
              show: true,
              position: 'bottom',
              // color: 'inherit'
            }
          }
        ]
      };
      myChart.setOption(option);
      // elementResizeDetectorMaker().listenTo(dom, () => {
      //   this.$nextTick(() => {
      //     echarts.init(dom).resize();
      //   });
      // });
    }
  }
};
</script>

<style scoped lang="less">
.healthReport {
  height: 100%;
  overflow-y: auto;
  background: rgba(239, 247, 248, 1);
  padding: 118px 0 80px;
  box-sizing: border-box;
  position: relative;
  .oActive {
    padding: 4px 12px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    border-radius: 6px;
    display: inline-block;
  }
  .oActive1 {
    background: rgba(130, 202, 255, 1);
  }
  .oActive2 {
    background: rgba(36, 182, 83, 1);
  }
  .oActive3 {
    background: rgba(36, 182, 83, 1);
  }
  .oActive4 {
    background: rgba(224, 32, 32, 1);
  }
  .oActive5 {
    background: rgba(224, 32, 32, 1);
  }
  .oActive6 {
    background: rgba(224, 32, 32, 1);
  }
  .healthReport-t {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    background: #fff;
    padding: 28px 50px;
    box-sizing: border-box;
    justify-content: space-around;
    display: flex;
    left: 0;
    .item {
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    .active {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        background: url('./img/active.png') no-repeat;
        background-size: 100% 100%;
        top: -18px;
        right: -18px;
      }
    }
  }
  .healthReport-c {
    background: #fff;
    padding: 52px 34px 44px;
    position: relative;
    z-index: 1;
    .advise {
      background: #fbfbfb;
      border-radius: 20px;
      padding: 28px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
      margin-bottom: 40px;
    }
    .trend-chart {
      box-sizing: border-box;
      margin-top: 10px;
      height: 400px;
    }
    .oTable {
      border-top: 2px solid #abced2;
      border-left: 2px solid #abced2;
      margin: 26px 0 60px;
      .item {
        display: flex;
        align-items: center;
        height: 60px;
        box-sizing: border-box;
        .item-l,
        .item-c,
        .item-r {
          flex: 1;
          height: 100%;
          box-sizing: border-box;
          text-align: center;
          padding: 12px 0;
          border-right: 2px solid #abced2;
          border-bottom: 2px solid #abced2;
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
      }
    }
    .targetItem {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 134px 0 40px;
      justify-content: space-between;
      .item {
        position: relative;
        .num {
          position: absolute;
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
          top: -44px;
          left: -12px;
          z-index: 6;
        }
        .item-b {
          margin-top: 40px;
          font-size: 22px;
          font-weight: 400;
          color: rgba(118, 192, 248, 1);
          line-height: 34px;
          text-align: center;
        }
        .item-t {
          width: 130px;
          height: 10px;
          border-radius: 5px;
          margin: 0 auto;
          background: linear-gradient(90deg, #e3fafc 0%, #c0ebf0 100%);
          position: relative;
          .circle {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            position: absolute;
            top: -14px;
            z-index: 99;
            left: 0.1rem;
            background: #089bab;
            box-shadow: 0px 2px 14px 0px rgba(207, 216, 209, 0.5);
            border: 4px solid #ffffff;
            .numItem {
              width: 100px;
              height: 60px;
              background: #fbfbfb;
              border-radius: 12px;
              text-align: center;
              font-size: 30px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.85);
              line-height: 60px;
              position: absolute;
              left: -50px;
              top: -100px;
            }
          }
        }
      }
      .item2 {
        .item-t {
          background: linear-gradient(270deg, #99dae1 0%, #c0ebf0 50%);
        }
        .item-b {
          color: rgba(36, 182, 83, 1);
        }
      }
      .item3 {
        .item-t {
          background: linear-gradient(270deg, #99dae1 0%, #c0ebf0 100%);
        }
        .item-b {
          color: rgba(36, 182, 83, 1);
        }
      }
      .item4 {
        .item-t {
          background: linear-gradient(270deg, #99dae1 0%, #79cdd6 100%);
        }
        .item-b {
          color: rgba(247, 181, 0, 1);
        }
      }
      .item5 {
        .item-t {
          background: linear-gradient(270deg, #79cdd6 0%, #57bac5 100%);
        }
        .item-b {
          color: rgba(250, 100, 0, 1);
        }
      }

      .item6 {
        .item-t {
          background: linear-gradient(270deg, #57bac5 0%, #089bab 100%);
        }
        .item-b {
          color: rgba(224, 32, 32, 1);
        }
      }
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      color: #089bab;
      line-height: 44px;
      padding-left: 22px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 36px;
        background: #089bab;
        border-radius: 8px;
        left: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .intro {
      font-size: 28px;
      margin: 20px 0;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }
    .thirdItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 56px;
      .item {
        width: 214px;
        height: 114px;
        background: rgba(8, 155, 171, 0.05);
        border-radius: 20px;
        border: 2px solid #089bab;
        text-align: center;
        padding-top: 16px;
        box-sizing: border-box;
        .item-t {
          font-size: 24px;
          font-weight: 400;
          color: #089bab;
          line-height: 34px;
        }
        .item-b {
          font-size: 36px;
          font-weight: 600;
          color: #089bab;
          line-height: 50px;
          position: relative;
          display: inline-block;
        }
      }
      .active {
        .item-b {
          &::after {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            background: #f7b500;
            border-radius: 6px;
            right: -16px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }
  .peopleComponent {
    padding: 0;
    margin-top: -18px;
    .peopleComponent-t {
      height: 572px;
      background: url('./img/topback.png') no-repeat;
      background-size: 100% 100%;
      padding: 0 28px;
      .peopleComponent-tt {
        display: flex;
        justify-content: space-between;
        padding: 46px 62px 0 44px;
        .peopleComponent-ttl {
          width: 82px;
          height: 226px;
          margin-right: 70px;
          .fat1 {
            width: 72px;
            height: 226px;
          }
          .fat2 {
            width: 74px;
            height: 224px;
          }
          .fat3 {
            width: 82px;
            height: 224px;
          }
          .fat4 {
            width: 90px;
            height: 226px;
          }
          .fat5 {
            width: 106px;
            height: 226px;
          }
        }
        .peopleComponent-ttr {
          display: flex;
          flex: 1;
          justify-content: space-between;
          padding-top: 12px;
          .peopleComponent-ttrlt {
            font-size: 24px;
            font-weight: 400;
            color: #089bab;
            line-height: 34px;
          }
          .peopleComponent-ttrlb {
            font-size: 52px;
            font-weight: 600;
            color: #089bab;
            line-height: 74px;
            margin-top: 34px;
            span {
              font-size: 28px;
            }
          }
          .peopleComponent-ttrr {
            text-align: center;
          }
          .peopleComponent-ttrrt {
            font-size: 24px;
            font-weight: 400;
            color: #089bab;
            line-height: 34px;
          }
          .peopleComponent-ttrrb {
            font-size: 52px;
            font-weight: 600;
            color: #089bab;
            line-height: 74px;
            margin-top: 34px;
          }
        }
      }
      .peopleComponent-tb {
        height: 250px;
        background: url('./img/zhiBack.png') no-repeat;
        background-size: 100% 100%;
        margin-top: 20px;
        padding: 32px 54px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .item-t {
          width: 142px;
          height: 142px;
          padding-top: 34px;
          box-sizing: border-box;
          background: #ffffff;
          border: 3px solid #fff3d2;
          font-size: 32px;
          font-weight: 500;
          color: #ffcd45;
          line-height: 44px;
          text-align: center;
          border-radius: 50%;
          .returnIsComTxt {
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            color: #d0d0d0;
            line-height: 34px;
          }
        }
        .item-b {
          display: flex;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          .img {
            width: 26px;
            height: 26px;
            margin-right: 6px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            font-size: 26px;
            font-weight: 500;
            color: #ffcd45;
            line-height: 36px;
          }
        }

        .MuscleMass {
          .item-t {
            border: 3px solid #d0f3ff;
            color: rgba(0, 190, 255, 1);
          }
          .item-b {
            .txt {
              color: rgba(0, 190, 255, 1);
            }
          }
        }
        .BodyWaterPercentage {
          .item-t {
            border: 3px solid #dbf5f5;
            color: rgba(27, 197, 156, 1);
          }
          .item-b {
            .txt {
              color: rgba(27, 197, 156, 1);
            }
          }
        }
      }
    }
    .peopleComponent-c {
      padding: 20px 34px;
      background: #fff;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }

    .peopleComponent-b {
      padding: 0 34px 50px;
      .title {
        margin: 86px 0 44px;
      }
      .peopleComponent-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
          .item-t {
            width: 186px;
            height: 186px;
          }
          .item-b {
            margin: 18px 0;
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 34px;
            .item-bt,
            .item-bb {
              position: relative;
              padding-left: 22px;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 10px;
                height: 10px;
                background: #dfeeff;
                border-radius: 50%;
              }
            }
          }
        }
        .Weight {
          #Weight {
            width: 100%;
            height: 100%;
          }
          .item-b {
            .item-bb {
              &::before {
                background: rgba(134, 190, 253, 1);
              }
            }
          }
        }
        .MuscleMass {
          #MuscleMass {
            width: 100%;
            height: 100%;
          }
          .item-b {
            .item-bt {
              &::before {
                background: rgba(252, 215, 115, 0.3);
              }
            }
            .item-bb {
              &::before {
                background: rgba(252, 215, 115, 1);
              }
            }
          }
        }
        .NoFat {
          #NoFat {
            width: 100%;
            height: 100%;
          }
          .item-b {
            .item-bt {
              &::before {
                background: rgba(150, 216, 201, 0.3);
              }
            }
            .item-bb {
              &::before {
                background: rgba(150, 216, 201, 1);
              }
            }
          }
        }
      }
      .peopleComponentList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
        .item {
          flex-shrink: 1;
          width: 212px;
          height: 186px;
          background: #f3fafb;
          border-radius: 16px;
          border: 2px solid #abced2;
          padding: 24px 0 0 18px;
          box-sizing: border-box;
          margin-bottom: 20px;
          .item-t {
            font-size: 24px;
            font-weight: 400;
            color: #898989;
            line-height: 34px;
          }
          .item-c {
            margin: 10px 0 14px;
          }
          .item-b {
            font-size: 40px;
            font-weight: 600;
            color: #089bab;
            line-height: 56px;
          }
        }
      }
    }
  }
  .dongmai {
    .intro {
      margin: 20px 0 74px 0;
    }
    .targetItem {
      .item {
        .item-t {
          width: 164px;
          height: 10px;
        }
      }
    }
    .High {
      .targetItem {
        .item {
          .item-t {
            width: 100px;
            height: 10px;
          }
        }
      }
    }
    .Heart {
      .targetItem {
        .item {
          .item-t {
            width: 220px;
            height: 10px;
          }
        }
      }
    }

    .careInfo {
      font-size: 24px;
      margin: 0 0 70px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.35);
      line-height: 34px;
    }
  }
  .BMI {
    .targetItem {
      .item {
        .item-t {
          width: 164px;
          height: 10px;
        }
      }
    }
  }
  .boneMD {
    .time {
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.35);
      line-height: 34px;
      margin: 24px 0 30px;
    }
    .sexAge {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 14px 28px;
      background: rgba(8, 155, 171, 0.05);
      border-radius: 6px;
      border: 2px solid #089bab;
      margin: 30px 0 16px;
      font-size: 28px;
      font-weight: 400;
      color: #089bab;
      line-height: 40px;
      &::after {
        content: '';
        position: absolute;
        width: 2px;
        background: #089bab;
        height: 48px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .targetItem {
      .item {
        .item-t {
          width: 220px;
          height: 10px;
        }
      }
    }
  }
  .healthReport-b {
    height: 212px;
    background: url('./img/bottomBack.png') no-repeat;
    background-size: 100% 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
}
</style>
